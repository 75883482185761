import React from "react"
import Header from "./header"
import {Link} from "react-router-dom"

const NotValidUser = ({setToken}) => {
    let homePage = ""
    if (window.sessionStorage.getItem("access_type") === "Master")
        homePage = (
            <Link to="/" className="alert-link">
                here
            </Link>
        )
    else if (window.sessionStorage.getItem("access_type") === "Institute")
        homePage = (
            <Link
                to={`/extension/${window.sessionStorage.getItem("code")}`}
                className="alert-link">
                here
            </Link>
        )
    else if (
        window.sessionStorage.getItem("access_type") === "Extension_Institute"
    )
        homePage = (
            <Link
                to={`/extension/${window.sessionStorage.getItem("code")}`}
                className="alert-link">
                here
            </Link>
        )
    return (
        <>
            <div className="container-fluid">
                <Header code={""} setToken={setToken} />
                <div className="main-error">
                    <div className="alert alert-danger" role="alert">
                        You don't have access to this page. Please go to your
                        home page from {homePage}.
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotValidUser
