import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import { baseUrl } from "../StaticValues"
import Header from "./components/header"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { LoaderSmall } from "./components/loaders"
import { useNavigate, useParams, Link } from "react-router-dom"

export default function Information({ insti_code }) {
    const [oldlogo, setOldLogo] = useState()
    const [logo, setLogo] = useState()
    const [coordinatorName, setCoordinatorName] = useState("")
    const [coordinatorPhone, setCoordinatorPhone] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const ref = useRef()

    const reset = () => {
        ref.current.value = ""
    }

    const downloadProfile = () => {
        axios({
            method: "get",
            url: baseUrl + "/getUser/?code=" + insti_code,
            headers: {
                Authorization:
                    "Bearer " + window.sessionStorage.getItem("access_token"),
            },
        })
            .then((res) => {
                if (res.data.logo == "") setLogo("")
                else
                    setLogo(
                        res.data.logo.split(",")[
                            res.data.logo.split(",").length - 1
                        ]
                    )
                setCoordinatorName(res.data.co_ordinator_name)
                setCoordinatorPhone(res.data.co_ordinator_phone)
            })
            .catch((err) => {})
    }

    useEffect(() => {
        downloadProfile()
    }, [])

    const handleSubmit = async (e) => {
        const form = e.target
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            e.target.className += " was-validated"
            return
        }
        e.preventDefault()
        let url = ""
        if (window.sessionStorage.getItem("access_type") == "Institute") {
            url = baseUrl + "/updateInstitutionCodeProfile/"
        } else {
            url = baseUrl + "/updateExtensionCodeProfile/"
        }
        setIsLoading(true)
        let formData = new FormData()
        formData.append("code", window.sessionStorage.getItem("user_name"))

        if (oldlogo != "") {
            formData.append("logo", oldlogo)
        }

        if (
            window.sessionStorage.getItem("access_type") ==
            "Extension_Institute"
        ) {
            formData.append("co_ordinator_name", coordinatorName)
            formData.append("co_ordinator_phone", coordinatorPhone)
        }

        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
                Authorization:
                    "Bearer " + window.sessionStorage.getItem("access_token"),
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    reset()
                    if (
                        window.sessionStorage.getItem("access_type") ==
                        "Extension_Institute"
                    ) {
                        setCoordinatorName(res.data.co_ordinator_name)
                        setOldLogo("")
                        setLogo(
                            res.data.logo.split(",")[
                                res.data.logo.split(",").length - 1
                            ]
                        )
                        setCoordinatorPhone(res.data.co_ordinator_phone)
                    } else {
                        setOldLogo("")
                        setLogo(
                            res.data.logo.split(",")[
                                res.data.logo.split(",").length - 1
                            ]
                        )
                    }
                    setIsLoading(false)
                    window.sessionStorage.setItem("logo", res.data.logo)
                    toast.success(`Information Changed Successfully!`, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
            .catch((err) => {
                setCoordinatorName("")
                setOldLogo("")
                setCoordinatorPhone("")
                if (err.response.status === 400) {
                    toast.error(`${err.response.data.message}`, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
            .finally(() => {})
    }

    return (
        <div className="container">
            <Header code={""} />
            <form
                onSubmit={handleSubmit}
                className="needs-validation"
                noValidate
            >
                <div className="form-group">
                    <label>Logo</label>
                    {logo != "" && (
                        <div>
                            <img
                                src={logo}
                                width="100px"
                                className="img-thumbnail"
                            />
                        </div>
                    )}

                    <br />
                    <input
                        type="file"
                        className="form-control-file border"
                        ref={ref}
                        onChange={(e) => {
                            if (e.target.files.length < 1) {
                                return
                            }
                            const file = e.target.files[0]
                            switch (file.type) {
                                case "image/png":
                                    //('image type is png');
                                    break
                                case "image/jpg":
                                    //('image/jpg')
                                    break
                                case "image/jpeg":
                                    //('image is jpeg')

                                    break
                                default:
                                    // image extension is not valid
                                    toast.error(
                                        "Invalid file type, Image expected",
                                        {
                                            position: "bottom-center",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        }
                                    )
                                    reset()
                                    return
                            }
                            setOldLogo(e.target.files[0])
                            setLogo(URL.createObjectURL(e.target.files[0]))
                        }}
                    />
                </div>
                {window.sessionStorage.getItem("access_type") ==
                    "Extension_Institute" && (
                    <>
                        <div className="form-group">
                            <label>Co-ordinator Name</label>
                            <input
                                type="text"
                                placeholder="Enter co-ordinator Name"
                                className="form-control"
                                value={coordinatorName}
                                onChange={(e) =>
                                    setCoordinatorName(e.target.value)
                                }
                            />
                            <div className="invalid-feedback">
                                Please fill out this field.
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Co-ordinator Phone</label>
                            <input
                                type="text"
                                placeholder="Enter co-ordinator Phone"
                                className="form-control"
                                value={coordinatorPhone}
                                onChange={(e) =>
                                    setCoordinatorPhone(e.target.value)
                                }
                            />
                            <div className="invalid-feedback">
                                Please fill out this field.
                            </div>
                        </div>
                    </>
                )}

                <div>
                    <button
                        type="submit"
                        className="btn btn-warning"
                        disabled={isLoading ? true : false}
                    >
                        {isLoading ? <LoaderSmall /> : "Submit"}
                    </button>
                </div>
            </form>
            <ToastContainer />
        </div>
    )
}
