import React, { Fragment, useState, useEffect, useRef, useCallback } from "react"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import "../css/landingPage.css"
import { Oval } from "react-loader-spinner"
import { baseUrl } from "../StaticValues"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"

import { Tabs, Tab } from "react-bootstrap-tabs"
import Dash from "./components/dash"
import Header from "./components/header"
// import Box from '@mui/material/Box';

const LandingPage = ({ setToken }) => {
    const gridRef = useRef()
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [gridApi, setGridApi] = useState(null)
    const [filter, setFilter] = useState("Institution")
    const [keyword, setKeyword] = useState("")
    const [tickers, setTickers] = useState({
        no_of_downloads: 0,
        no_of_0_article_read: 0,
        no_of_active_user_daily: 0,
        no_of_active_user_monthly: 0,
        no_of_extensions: 0,
    })
    const [isLoading, setIsLoading] = useState(true)

    const [columnDefs] = useState([
        //{ field: "id" },
        {
            field: "code",
            filter: true,
        },
        { field: "alias", headerName: "Name", filter: true, sortable: true },
        {
            field: "no_of_user",
            headerName: "Average Test Score",
            sortable: true,
        },
        {
            field: "no_with_0_article_read",
            headerName: "Users with 0 reads",
            sortable: true,
        },
        {
            field: "no_of_active_user",
            headerName: "Number of Active Users",
            sortable: true,
            resizable: true,
        },
        {
            field: "code",
            headerName: "Action",
            cellRenderer: "LinkComponent",
            cellClass: "vertical-middle",
        },
    ])

    const onGridReady = (params) => {
        setGridApi(params.api)
    }

    const InstituteCode = () => {
        gridApi.showLoadingOverlay()

        const url = baseUrl + "/getAllInstituteCode"

        axios
            .get(url, {
                headers: {
                    Authorization:
                        "Bearer " +
                        window.sessionStorage.getItem("access_token"), //the token is a variable which holds the token
                },
            })
            .then((res) => {
                gridApi.hideOverlay()
                setData(res.data.result)
                setFilteredData(
                    res.data.result.filter((single) => single.type === filter)
                )
                setTickers((prevState) => ({
                    ...prevState,
                    no_of_downloads: res.data.total_number_of_user,
                    no_of_0_article_read:
                        res.data.no_of_user_with_0_article_read,
                    no_of_active_users: res.data.no_of_active_user,
                    no_of_extensions: res.data.total_extension_code,
                }))
                if (res.data.result.length <= 0) gridApi.showNoRowsOverlay()
            })
            .catch((err) => {
                console.log("err", err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleQuickFilter = (event) => {
        gridRef.current.api.setQuickFilter(event.target.value)
    }

    const onTypeClickListener = (event) => {
        setFilter(event.target.text)
        setFilteredData(
            data.filter((single) => single.type === event.target.text)
        )
    }

    const onKeywordChangeListener = (event) => {
        setKeyword(event.target.value)
    }

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit()
        gridRef.current.api.setDomLayout("autoHeight")
    }, [])
    useEffect(() => {
        if (gridApi) InstituteCode()
    }, [gridApi])

    // useEffect(() => {
    //     InstituteCode()
    // }, [])

    return (
        <Fragment>
            <section className="blackBG">
                <div className="container-fluid">
                    <Header code={""} setToken={setToken} />
                    <Dash
                        tickers={tickers}
                        isLoading={isLoading}
                        contains="Institutes"></Dash>
                    <br />
                    <Tabs>
                        <Tab label="Browse">
                            <input
                                type="text"
                                id="filter-text-box"
                                placeholder="Search..."
                                onChange={handleQuickFilter}
                                className="form-control"></input>
                            <br />
                            <ul className="nav nav-pills float-right">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${filter == "Institution"
                                            ? "active"
                                            : ""
                                            }`}
                                        href={void 0}
                                        onClick={onTypeClickListener}>
                                        Institution
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${filter == "Individual"
                                            ? "active"
                                            : ""
                                            }`}
                                        href={void 0}
                                        onClick={onTypeClickListener}>
                                        Individual
                                    </a>
                                </li>
                            </ul>
                            <br />
                            <br />
                            <div className="rr-card">
                                <div
                                    className="ag-theme-alpine"
                                    style={{ height: "300px", width: "100%" }}>
                                    <AgGridReact
                                        rowData={filteredData}
                                        ref={gridRef}
                                        pagination={true}
                                        paginationPageSize={10}
                                        columnDefs={columnDefs}
                                        onFirstDataRendered={
                                            onFirstDataRendered
                                        }
                                        frameworkComponents={{
                                            LinkComponent,
                                        }}
                                        onGridReady={onGridReady}
                                        enableCellTextSelection={
                                            true
                                        }></AgGridReact>
                                </div>
                            </div>
                        </Tab>
                        <Tab label="Search">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="filter-text-box"
                                    placeholder="Enter Keyword..."
                                    onChange={onKeywordChangeListener}
                                    className="form-control"
                                    value={keyword}></input>
                            </div>
                            <div className="form-group">
                                <Link to={`/view-data/${keyword}`}>
                                    <button className="btn btn-warning">
                                        Search
                                    </button>
                                </Link>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </section>
        </Fragment>
    )
}

function LinkComponent(props) {
    return (
        <>
            <Link to={`/extension/${props.value}`}>
                <button type="button" className="btn btn-dark btn-sm">
                    View Data
                </button>
            </Link>
            &nbsp;
            <Link to={`/view-data/${props.value}`}>
                <button type="button" className="btn btn-secondary btn-sm">
                    Master Data
                </button>
            </Link>
        </>
    )
}

export default LandingPage
