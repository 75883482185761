import React, {useState, useEffect, useRef} from "react"
import axios from "axios"
import {baseExtendUrl} from "../StaticValues"
import Header from "./components/header"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {LoaderSmall} from "./components/loaders"
import {useNavigate, useParams, Link} from "react-router-dom"

export default function Categories({insti_code}) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    function downloadExtendExtensionCode() {
        axios({
            method: "get",
            url:
                baseExtendUrl +
                "/extend_extention_code/retrieveExtendExtentionCode?institute_code=" +
                insti_code.split("-")[0] +
                "&extension_code=" +
                insti_code.split("-")[1],
            headers: {
                Authorization:
                    "Bearer " + window.sessionStorage.getItem("access_token"),
            },
        })
            .then((res) => {
                setData(res.data)
            })
            .catch((err) => {})
    }

    useEffect(() => {
        downloadExtendExtensionCode()
    }, [])

    return (
        <div className="container categories">
            <Header code={""} />
            <h3>Clusters</h3>
            <p>Manage data clusters from here</p>
            <ul>
                {data
                    .filter((data) => data.extend_extension_code == null)
                    .map((single) => {
                        return (
                            <li key={single.id}>
                                <i className="fa-solid fa-angles-right"></i>{" "}
                                {single.code} - {single.alias} [
                                {single.number_of_user}]
                                <Link
                                    to={`/view-data/${insti_code}-${single.code}`}>
                                    &nbsp;View Data
                                </Link>
                                <ul>
                                    {data
                                        .filter(
                                            (data) =>
                                                data.extend_extension_code ==
                                                single.id
                                        )
                                        .map((single2) => {
                                            return (
                                                <li key={single2.id}>
                                                    <i className="fa-solid fa-circle-dot"></i>{" "}
                                                    {single2.code} -{" "}
                                                    {single2.alias} [
                                                    {single2.number_of_user}]
                                                    <Link
                                                        to={`/view-data/${insti_code}-${single2.code}`}>
                                                        &nbsp;View Data
                                                    </Link>
                                                    <ul>
                                                        {data
                                                            .filter(
                                                                (data) =>
                                                                    data.extend_extension_code ==
                                                                    single2.id
                                                            )
                                                            .map((single3) => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            single3.id
                                                                        }>
                                                                        <i className="fa-solid fa-circle-dot"></i>{" "}
                                                                        {
                                                                            single3.code
                                                                        }{" "}
                                                                        -{" "}
                                                                        {
                                                                            single3.alias
                                                                        }{" "}
                                                                        [
                                                                        {
                                                                            single3.number_of_user
                                                                        }
                                                                        ]
                                                                        <Link
                                                                            to={`/view-data/${insti_code}-${single3.code}`}>
                                                                            &nbsp;View
                                                                            Data
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            })}
                                                    </ul>
                                                </li>
                                            )
                                        })}
                                </ul>
                            </li>
                        )
                    })}
            </ul>
        </div>
    )
}
