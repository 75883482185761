import React, {useState, useEffect} from "react"
import axios from "axios"
import {baseUrl} from "../StaticValues"
import {Navigate, useNavigate} from "react-router-dom"
import Header from "./components/header"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {LoaderSmall} from "./components/loaders"

export default function Login({setToken, setUserType}) {
    const [username, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [code, setCode] = useState()
    const [type, setType] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const history = useNavigate()

    async function loginUser(credentials) {
        const url = baseUrl + "/login/"
        setIsLoading(true)
        let loginFormData = new FormData()
        loginFormData.append("user", credentials.username)
        loginFormData.append("password", credentials.password)
        return axios({
            method: "post",
            url: url,
            data: loginFormData,
        })
            .then((res) => {
                if (res.status === 200) {
                    setToken(res.data.id)
                    setCode(res.data.code)
                    setType(res.data.access_type)
                    setIsLoading(false)
                    // console.log(res.data)
                    window.sessionStorage.setItem("user_name", res.data.user)
                    window.sessionStorage.setItem(
                        "access_type",
                        res.data.access_type
                    )
                    window.sessionStorage.setItem(
                        "access_token",
                        res.data.access_token
                    )
                    window.sessionStorage.setItem("token", res.data.id)
                    window.sessionStorage.setItem("code", res.data.code)
                    window.sessionStorage.setItem("logo", res.data.logo)
                    setUserType(res.data.access_type)
                    if (res.data.access_type === "Master") history("/")
                    else if (res.data.access_type === "Institute")
                        history("/extension/" + res.data.code)
                    else if (res.data.access_type === "Extension_Institute")
                        history("/extension/" + res.data.code)
                    else if (
                        res.data.access_type === "Extend_Extension_Institute"
                    )
                        history("/extension/" + res.data.code)
                }
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    setUserName("")
                    setPassword("")
                    setIsLoading(false)
                    toast.error(`${err.response.data.message}`, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
            .finally(() => {})
    }

    useEffect(() => {
        return () => {
            setCode()
            setType()
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        await loginUser({
            username,
            password,
        })
    }

    return (
        <div className="container">
            <Header code={""} />
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Username/Code</label>
                    <input
                        type="text"
                        placeholder="Enter your username or code"
                        className="form-control"
                        value={username}
                        onChange={(e) => setUserName(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input
                        type="password"
                        placeholder="Enter your password"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div>
                    <button
                        type="submit"
                        className="btn btn-warning"
                        disabled={isLoading ? true : false}>
                        {isLoading ? <LoaderSmall /> : "Submit"}
                    </button>
                </div>
            </form>
            <ToastContainer />
        </div>
    )
}
