import React, {
    Fragment,
    useState,
    useEffect,
    useRef,
    useCallback,
} from "react"
import logo from "../../assets/images/rr-logo.svg"
import { Link, useNavigate } from "react-router-dom"
import { LoaderSmall } from "./loaders"

const Header = ({ insti_code, code, setToken, iLogo = "" }) => {
    const history = useNavigate()
    let homePage = <img src={logo} width="250px" />
    if (window.sessionStorage.getItem("access_type") === "Master")
        homePage = (
            <Link to="/" className="alert-link">
                <img src={logo} width="250px" />
            </Link>
        )
    else if (window.sessionStorage.getItem("access_type") === "Institute")
        homePage = (
            <Link to={`/extension/${window.sessionStorage.getItem("code")}`}>
                <img src={logo} width="250px" />
            </Link>
        )
    else if (
        window.sessionStorage.getItem("access_type") === "Extension_Institute"
    )
        homePage = (
            <Link to={`/view-data/${window.sessionStorage.getItem("code")}`}>
                <img src={logo} width="250px" />
            </Link>
        )
    return (
        <div className="hero-section">
            <div className="row">
                <div className="col-lg-8 float-left rr-logo">{homePage}</div>
                {window.sessionStorage.getItem("user_name") != null && (
                    <div className="col-lg-4 float-right insti-logo">
                        {iLogo != "" &&
                            iLogo != "N/A" &&
                            window.sessionStorage.getItem("access_type") !=
                                "Master" && (
                                <>
                                    {iLogo.split(",").length > 1 && (
                                        <>
                                            <img
                                                src={iLogo.split(",")[0]}
                                                width="130px"
                                            />
                                            <img
                                                src={iLogo.split(",")[1]}
                                                width="130px"
                                            />
                                        </>
                                    )}
                                    {iLogo.split(",").length == 1 && (
                                        <>
                                            <img
                                                src={iLogo.split(",")[0]}
                                                width="130px"
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        <div className="dropdown show">
                            <a
                                className="btn btn-secondary dropdown-toggle"
                                href="#"
                                role="button"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {window.sessionStorage.getItem("user_name")}
                            </a>
                            <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuLink"
                            >
                                {window.sessionStorage.getItem("access_type") !=
                                    "Master" && (
                                    <>
                                        <Link
                                            className="dropdown-item"
                                            to="/information"
                                        >
                                            Update Information
                                        </Link>
                                    </>
                                )}
                                {window.sessionStorage.getItem("access_type") ==
                                    "Extension_Institute" && (
                                    <Link
                                        className="dropdown-item"
                                        to="/categories"
                                    >
                                        Clusters
                                    </Link>
                                )}
                                <Link
                                    className="dropdown-item"
                                    to="/change-password"
                                >
                                    Change Password
                                </Link>
                                <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => {
                                        setToken("")
                                        window.sessionStorage.removeItem(
                                            "user_name"
                                        )
                                        window.sessionStorage.removeItem("code")
                                        window.sessionStorage.removeItem(
                                            "access_type"
                                        )
                                        window.sessionStorage.removeItem(
                                            "token"
                                        )
                                        window.sessionStorage.removeItem("logo")
                                        history("/")
                                    }}
                                >
                                    Logout
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {code != "" && (
                <nav
                    aria-label="breadcrumb"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                    <ol className="breadcrumb">
                        <li
                            className="breadcrumb-item active"
                            aria-current="page"
                        >
                            {code === "loading" ? (
                                <LoaderSmall />
                            ) : (
                                <>
                                    <span>{code} </span>
                                    <span className="bold-text">
                                        {"[" + insti_code + "]"}
                                    </span>
                                </>
                            )}
                        </li>
                    </ol>
                </nav>
            )}
        </div>
    )
}

export default Header
