import React from "react"
import {LoaderSmall} from "./loaders"
import JsPDF from "jspdf"

const Dash = ({tickers, isLoading, contains}) => {
    const generatePDF = () => {
        const report = new JsPDF("landscape", "pt", "a2")
        report.html(document.querySelector("#tickers")).then(() => {
            report.save("report.pdf")
        })
    }
    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                }}>
                <button className="btn btn-secondary" onClick={generatePDF}>
                    <i className="fa-solid fa-file-arrow-down"></i> Export to
                    PDF
                </button>
            </div>
            <br />
            <div className="row" id="tickers">
                <div className="col-lg-4 col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <h2>
                                {isLoading ? (
                                    <LoaderSmall />
                                ) : (
                                    tickers.total_no_of_users
                                )}
                            </h2>
                            <h5 className="card-title">
                                Total number of students
                            </h5>
                            <p className="card-text">
                                Total number of students in the institution.
                            </p>
                            {/* <Link
                                            to={`/view-data/${instituteCode}`}
                                            className="btn btn-warning"
                                        >
                                            View Data
                                        </Link> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <h2>
                                {isLoading ? (
                                    <LoaderSmall />
                                ) : (
                                    tickers.no_of_downloads
                                )}
                            </h2>
                            <h5 className="card-title">No. of Downloads</h5>
                            <p className="card-text">
                                This includes all users who have downloaded the
                                app from either playstore or Appstore and have
                                used the institute id provided to them.
                            </p>
                            {/* <Link
                                            to={`/view-data/${instituteCode}`}
                                            className="btn btn-warning"
                                        >
                                            View Data
                                        </Link> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <h2>
                                {isLoading ? (
                                    <LoaderSmall />
                                ) : (
                                    tickers.no_of_extensions
                                )}
                            </h2>
                            <h5 className="card-title">No. Of {contains}</h5>
                            <p className="card-text">
                                No. Of {contains} covered under the code{" "}
                                {window.sessionStorage.getItem("code")}.
                            </p>
                            {/* <a
                                            className="btn btn-warning"
                                            onClick={() => {
                                                setAggrid(true)
                                            }}
                                        >
                                            View Data
                                        </a> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <h2>
                                {isLoading ? (
                                    <LoaderSmall />
                                ) : (
                                    tickers.no_of_active_user_daily
                                )}
                            </h2>
                            <h5 className="card-title">
                                No. of active users daily
                            </h5>
                            <p className="card-text">
                                Daily active users are those who open the app in
                                a day.
                            </p>
                            {/* <Link
                                            to={`/view-data/${instituteCode}`}
                                            className="btn btn-warning"
                                        >
                                            View Data
                                        </Link> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <h2>
                                {isLoading ? (
                                    <LoaderSmall />
                                ) : (
                                    tickers.no_of_active_user_monthly
                                )}
                            </h2>
                            <h5 className="card-title">
                                No. of active users monthly
                            </h5>
                            <p className="card-text">
                                Monthly active users are those who open the app
                                in one month.
                            </p>
                            {/* <Link
                                            to={`/view-data/${instituteCode}`}
                                            className="btn btn-warning"
                                        >
                                            View Data
                                        </Link> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <h2>
                                {isLoading ? (
                                    <LoaderSmall />
                                ) : (
                                    tickers.no_of_0_article_read
                                )}
                            </h2>
                            <h5 className="card-title">Users with 0 reads</h5>
                            <p className="card-text">
                                Users who haven't read even one article since
                                they downloaded the app.
                            </p>
                            {/* <Link
                                            to={`/view-data/${instituteCode}`}
                                            className="btn btn-warning"
                                        >
                                            View Data
                                        </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dash
