import {useCallback, Fragment, useState, useEffect, useRef} from "react"
import axios from "axios"
import {useParams, Link, useNavigate} from "react-router-dom"
import "../css/detail.css"
import {baseUrl} from "../StaticValues"
import {AgGridReact} from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import Dash from "./components/dash"
import Header from "./components/header"
import _ from "lodash"
import BooleanFilter from "./BooleanFilter"

const DetailPage = ({setToken}) => {
    const gridRef = useRef()
    const params = useParams()
    const history = useNavigate()
    const [gridApi, setGridApi] = useState(null)
    const instituteCode = params.instituteCode
    const [searchText, setSearchText] = useState("")
    const [subjects, setSubjects] = useState([])
    const [subjectFilterText, setSubjectFilterText] = useState("")
    const [showSelectAll, setShowSelectAll] = useState(true)
    const [checkedState, setCheckedState] = useState([])
    const [tickers, setTickers] = useState({
        no_of_downloads: 0,
        total_no_of_users: 0,
        no_of_0_article_read: 0,
        no_of_active_user_daily: 0,
        no_of_active_user_monthly: 0,
        no_of_extensions: 0,
        alias: "loading",
    })
    const [isFirstTime, setIsFirstTime] = useState(true)
    const [isLoading, setIsLoading] = useState(true)

    const [columnDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            width: "75px",
        },
        {
            headerName: "User ID",
            field: "user_id",
            width: 0,
            hide: true,
            suppressToolPanel: true,
        },
        {
            field: "is_logged_in",
            headerName: "Download Status",
            cellRenderer: "DownloadComponent",
            filter: BooleanFilter,
        },
        {
            field: "user_name",
            headerName: "Student Name",
            filter: true,
            resizable: true,
            sortable: true,
            cellRenderer: "UsernameComponent",
        },
        {
            field: "user_phonenumber",
            headerName: "Student Phone Number",
            filter: true,
            resizable: true,
            sortable: true,
        },
        {
            field: "user_email",
            headerName: "Student Email",
            filter: true,
            resizable: true,
            sortable: true,
        },
        {
            field: "student_id",
            headerName: "Student Id",
            filter: true,
            resizable: true,
            sortable: true,
            hide: true,
        },
        {
            field: "university_reg_no",
            headerName: "University Registration Number",
            filter: true,
            resizable: true,
            sortable: true,
            hide: true,
        },
        {
            field: "course_name",
            headerName: "Course Name",
            resizable: true,
            hide: true,
        },
        {
            field: "year_of_study",
            headerName: "Year of Study",
            filter: true,
            sortable: true,
            hide: true,
        },
        {
            field: "no_articles_read",
            headerName: "Number of Articles Read",
        },
        {
            field: "onboarding_test_score",
            headerName: "Onboarding Test",
        },
        {
            field: "final_test_score",
            headerName: "Final Test",
        },
        {
            field: "total_time",
            headerName: "Total time spent on the app",
        },
        // {
        //     field: "min_time",
        //     headerName: "Min time spent/Article",
        // },
        // {
        //     field: "max_time",
        //     headerName: "Max time spent/Article",
        // },
        // {
        //     field: "avg_time_spent",
        //     headerName: "Avg time spent/Article",
        // },
        // {
        //     field: "aggregate_quiz_score",
        //     headerName: "Aggregate Quiz Score",
        // },
    ])

    const onGridReady = (params) => {
        setGridApi(params.api)
        params.api.sizeColumnsToFit()
    }

    const onBtFirst = useCallback(() => {
        gridApi.paginationGoToFirstPage()
    }, [])

    const onBtLast = useCallback(() => {
        gridApi.paginationGoToLastPage()
    }, [])

    const handleCheckbox = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        )

        setCheckedState(updatedCheckedState)
        var checkedSubjects = []
        updatedCheckedState.map((item, index) => {
            if (item) {
                checkedSubjects.push(subjects[index])
            }
        })
        if (checkedSubjects.length != 0) {
            setShowSelectAll(false)
        } else {
            setShowSelectAll(true)
        }
        setSubjectFilterText(checkedSubjects.join(","))
    }

    const selectAllHandler = () => {
        if (showSelectAll) {
            const updatedCheckedState = checkedState.map((item, index) => false)
            setCheckedState(updatedCheckedState)
            setSubjectFilterText(subjects.join(","))
        } else {
            const updatedCheckedState = checkedState.map((item, index) => true)
            setCheckedState(updatedCheckedState)
            setSubjectFilterText("")
        }

        setShowSelectAll(!showSelectAll)
    }

    const getSubjects = () => {
        const param = instituteCode

        const apiurl =
            baseUrl +
            `/getAllSubjects?code=${
                param.split("-")[0] + "-" + param.split("-")[1]
            }`
        axios({
            method: "get",
            url: apiurl,
            headers: {
                Authorization:
                    "Bearer " + window.sessionStorage.getItem("access_token"),
            },
        })
            .then((res) => {
                var newArray = []
                res.data.map((value, index) => {
                    newArray.push(true)
                })
                if (subjects.length == 0) {
                    setCheckedState(newArray)
                }
                setSubjects(res.data)
            })
            .catch(() => {})
            .finally(() => {
                setTimeout(() => {}, 0)
            })
    }

    // const handleQuickFilter = (event) => {
    //     gridApi.setQuickFilter(event.target.value)
    // }

    const search = _.debounce((text) => {
        //console.log(text)
        setSearchText(text.target.value)
    }, 1000)

    function printWindow() {
        window.print()
    }

    useEffect(() => {
        if (subjects.length == 0) getSubjects()
        if (gridApi) {
            const dataSource = {
                getRows: (params) => {
                    // Use startRow and endRow for sending pagination to Backend
                    // params.startRow : Start Page
                    // params.endRow : End Page
                    gridApi.showLoadingOverlay()
                    const {startRow, endRow, filterModel, sortModel} = params
                    const page = endRow / 50

                    let apiurl =
                        baseUrl +
                        `/getAnalysisResult?institute_code=${instituteCode}&page=${page}`
                    // Searching
                    if (searchText) {
                        apiurl += `&q=${searchText}`
                    }

                    if (subjectFilterText) {
                        apiurl += `&subject_filter=1&subject_parameters=${subjectFilterText}`
                    }

                    //Sorting
                    if (sortModel.length) {
                        const {colId, sort} = sortModel[0]
                        apiurl += `&sort=${colId}&order=${sort}`
                    }
                    //Filtering
                    const filterKeys = Object.keys(filterModel)
                    filterKeys.forEach((filter) => {
                        apiurl += `&${filter}=${filterModel[filter].filter}`
                    })
                    //console.log(apiurl)
                    axios
                        .get(apiurl, {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    window.sessionStorage.getItem(
                                        "access_token"
                                    ), //the token is a variable which holds the token
                            },
                        })
                        .then((res) => {
                            // console.clear()
                            // console.log("data", res.data)
                            // setDetailedData(res.data)
                            gridApi.hideOverlay()
                            if (res.data.data.length <= 0) {
                                gridApi.showNoRowsOverlay()
                                setTickers((prevState) => ({
                                    ...prevState,
                                    alias: res.data.alias,
                                }))
                            } else {
                                if (isFirstTime) {
                                    setTickers((prevState) => ({
                                        ...prevState,
                                        total_no_of_users:
                                            res.data.total_number_of_students,
                                        no_of_downloads:
                                            res.data
                                                .total_number_of_user_min_1_logged_in,
                                        no_of_0_article_read:
                                            res.data
                                                .no_of_user_with_0_article_read,
                                        no_of_active_user_daily:
                                            res.data
                                                .total_no_of_active_user_daily,
                                        no_of_active_user_monthly:
                                            res.data
                                                .total_no_of_active_user_monthly,
                                        no_of_extensions:
                                            res.data.total_extension_code,
                                        alias: res.data.alias,
                                    }))
                                    setIsFirstTime(false)
                                }
                                params.successCallback(
                                    res.data.data,
                                    res.data.total
                                )
                            }
                        })
                        .catch((err) => {
                            params.successCallback([], 0)
                            gridApi.showNoRowsOverlay()
                            if (err.response.status == 400)
                                alert(err.response.data.message)
                        })
                        .finally(() => {
                            setIsLoading(false)
                        })
                },
            }
            gridApi.setDatasource(dataSource)
        }
    }, [gridApi, searchText, subjectFilterText])

    return (
        <Fragment>
            <section>
                <div className="container-fluid">
                    <Header
                        insti_code={instituteCode}
                        code={tickers.alias}
                        setToken={setToken}
                        iLogo={window.sessionStorage.getItem("logo")}
                    />
                    <Dash
                        tickers={tickers}
                        isLoading={isLoading}
                        contains="Institutes"></Dash>
                    <br />
                    <input
                        type="text"
                        id="filter-text-box"
                        placeholder="Search..."
                        onChange={search}
                        className="form-control"></input>
                    <br />
                    <button
                        onClick={printWindow}
                        className="float-right btn btn-secondary">
                        <i className="fa-solid fa-file-arrow-down"></i> Export
                        to PDF
                    </button>
                    <br />
                    <div className="rr-card">
                        <div className="paging-div align-items-end mb-1 mt-1">
                            <button
                                className="btn btn-sm btn-dark"
                                onClick={onBtFirst}>
                                First Page
                            </button>
                            <button
                                className="btn btn-sm btn-dark ml-1"
                                onClick={onBtLast}>
                                Last Page
                            </button>
                        </div>
                        {subjects.length != 0 && (
                            <div className="w-100 chbx-group pt-2 mb-2 pl-2">
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="all"
                                        value="All"
                                        checked={showSelectAll}
                                        onChange={selectAllHandler}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="all">
                                        All
                                    </label>
                                </div>
                                {subjects.map((subject, index) => {
                                    return (
                                        <div
                                            className="form-check form-check-inline"
                                            key={index}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={index}
                                                value={subject}
                                                checked={checkedState[index]}
                                                onChange={() =>
                                                    handleCheckbox(index)
                                                }
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor={index}>
                                                {subject}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                        <div
                            className="ag-theme-alpine"
                            style={{height: "80vh", width: "100%"}}>
                            <AgGridReact
                                rowModelType={"infinite"}
                                pagination={true}
                                paginationPageSize={50}
                                cacheBlockSize={50}
                                columnDefs={columnDefs}
                                onGridReady={onGridReady}
                                enableCellTextSelection={true}
                                frameworkComponents={{
                                    DownloadComponent,
                                    UsernameComponent,
                                }}></AgGridReact>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )

    function handleClick(data) {
        history(
            `/user-details/${data.user_id}?download_status=${data.is_logged_in}&user_name=${data.user_name}&avg_time_spent=${data.avg_time_spent}&aggregate_quiz_score=${data.aggregate_quiz_score}`
        )
    }

    function UsernameComponent(props) {
        return (
            <>
                {/* ${props.data.userId} */}
                <a href="" onClick={() => handleClick(props.data)}>
                    {props.value}
                </a>
            </>
        )
    }

    function DownloadComponent(props) {
        if (props.value == true) {
            return (
                <div>
                    <i className="fa-solid fa-circle-check correct"></i>
                </div>
            )
        } else {
            return (
                <div>
                    <i className="fa-solid fa-circle-xmark wrong"></i>
                </div>
            )
        }
    }
}

export default DetailPage
