import React, {Fragment, useEffect, useState, useRef, useCallback} from "react"
import "../css/extension.css"
import {useNavigate, useParams, Link} from "react-router-dom"
import axios from "axios"
import {baseUrl, baseExtendUrl} from "../StaticValues"
import {AgGridReact, AgGridColumn} from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import Dash from "./components/dash"
import Header from "./components/header"

const ExtensionPage = ({setToken}) => {
    var ext = null
    const history = useNavigate()
    const gridRef = useRef()
    const [gridApi, setGridApi] = useState(null)
    const [code, setCode] = useState([])
    const [extension, setExtension] = useState(null)
    const [extensionHistory, setExtensionHistory] = useState([])
    const params = useParams()
    const instituteCode = params.instituteCode
    const [contains, setContains] = useState("Institutes")
    const [tickers, setTickers] = useState({
        total_no_of_users: 0,
        no_of_downloads: 0,
        no_of_0_article_read: 0,
        no_of_active_user_daily: 0,
        no_of_active_user_monthly: 0,
        no_of_extensions: 0,
        alias: "loading",
    })
    const [isLoading, setIsLoading] = useState(true)

    const onBtnExport = useCallback(() => {
        params.columnKeys = [
            "code",
            "alias",
            "co_ordinator_name",
            "co_ordinator_phone",
            "no_of_user",
            "number_of_students",
            "no_with_0_article_read",
            "no_of_active_user_daily",
            "no_of_active_user_monthly",
            "number_of_institute",
        ]
        gridRef.current.api.exportDataAsCsv(params)
    }, [])

    const [columnDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            width: 75,
        },
        {
            field: "code",
            filter: true,
            resizable: true,
            hide: true,
        },
        {
            field: "alias",
            headerName: `Institutes`,
            cellRenderer: "LinkComponent",
            sortable: true,
            resizable: true,
        },
        {
            field: "no_of_downloads",
            headerName: "Number of Downloads",
            cellRenderer: "JudgeComponent",
            sortable: true,
            resizable: true,
        },
        {
            field: "no_of_user",
            headerName: "Total Number of Students",
            sortable: true,
            resizable: true,
        },
        {
            field: "no_with_0_article_read",
            headerName: "Users with 0 reads",
            sortable: true,
            resizable: true,
        },
        {
            field: "no_of_active_user_daily",
            headerName: "Number of Active Users Daily",
            sortable: true,
            resizable: true,
        },
        {
            field: "no_of_active_user_monthly",
            headerName: "Number of Active Users Monthly",
            sortable: true,
            resizable: true,
        },
        {
            field: "number_of_institute",
            headerName: `Number of Institutes`,
            sortable: true,
            resizable: true,
        },
        {
            field: "co_ordinator_name",
            headerName: "Co-ordinator Name",
            filter: true,
            resizable: true,
        },
        {
            field: "co_ordinator_phone",
            headerName: "Co-ordinator Phone",
            filter: true,
            resizable: true,
        },
        // {
        //     field: "code",
        //     headerName: "Action",
        //     cellRenderer: "LinkComponent",
        //     cellClass: "vertical-middle",
        // },
    ])

    const [columns, setColumns] = useState(columnDefs)

    const onGridReady = (params) => {
        setGridApi(params.api)
    }

    const handleClick = (code) => {
        if (ext === null) {
            ext = code
            setExtension(code)
            setExtensionHistory((current) => [...current, ext])
        } else {
            ext = ext + "-" + code
            setExtension(ext + "-" + code)
            setExtensionHistory((current) => [...current, ext + "-" + code])
        }
    }

    const getAllExtensionCode = () => {
        gridRef.current.api.showLoadingOverlay()
        setIsLoading(true)
        //console.log(extension)
        let apiurl = ""
        if (
            window.sessionStorage.getItem("access_type") ===
            "Extension_Institute"
        ) {
            if (extension !== null) {
                apiurl =
                    baseExtendUrl +
                    `/extend_extention_code/retrieveChainExtendExtensionCode/?code=${instituteCode}-${extension}`
            } else {
                apiurl =
                    baseExtendUrl +
                    `/extend_extention_code/retrieveChainExtendExtensionCode/?code=${instituteCode}`
                setExtension(
                    instituteCode.split("-")[instituteCode.split("-").length]
                )
            }
        } else if (
            window.sessionStorage.getItem("access_type") ===
            "Extend_Extension_Institute"
        ) {
            apiurl =
                baseExtendUrl +
                `/extend_extention_code/retrieveChainExtendExtensionCode/?code=${instituteCode}`
        } else {
            if (extension !== null) {
                apiurl =
                    baseExtendUrl +
                    `/extend_extention_code/retrieveChainExtendExtensionCode/?code=${instituteCode}-${extension}`
            } else {
                apiurl =
                    baseUrl +
                    `/getAllExtensionCode/?institute_code=${instituteCode}`
            }
        }
        axios
            .get(apiurl, {
                headers: {
                    Authorization:
                        "Bearer " +
                        window.sessionStorage.getItem("access_token"), //the token is a variable which holds the token
                },
            })
            .then((res) => {
                // console.clear()
                // console.log(res.data)
                setCode(res.data.result)
                if (res.data.contains != "") setContains(res.data.contains)

                columnDefs[8].headerName = `Number of ${res.data.contains}`
                columnDefs[2].headerName = `${res.data.contains}`
                setColumns(columnDefs)

                setTickers((prevState) => ({
                    ...prevState,
                    total_no_of_users: res.data.total_number_of_students,
                    no_of_downloads:
                        res.data.total_number_of_user_min_1_logged_in,
                    no_of_0_article_read:
                        res.data.no_of_user_with_0_article_read,
                    no_of_active_user_daily:
                        res.data.total_no_of_active_user_daily,
                    no_of_active_user_monthly:
                        res.data.total_no_of_active_user_monthly,
                    no_of_extensions: res.data.total_extension_code,
                    alias: res.data.alias,
                }))
                gridRef.current.api.hideOverlay()
                if (res.data.result.length <= 0) {
                    gridRef.current.api.showNoRowsOverlay()
                    if (
                        window.sessionStorage.getItem("access_type") !==
                        "Extension_Institute"
                    ) {
                        if (extension != null) {
                            let all_extensions = extension.split("-")
                            if (all_extensions.length <= 1) {
                                history(
                                    `/view-data/${params.instituteCode}-${extension}`
                                )
                            } else {
                                let ext1 = all_extensions[0]
                                let ext2 =
                                    all_extensions[all_extensions.length - 1]
                                history(
                                    `/view-data/${params.instituteCode}-${ext1}-${ext2}`
                                )
                            }
                        }
                    }
                    if (
                        window.sessionStorage.getItem("access_type") !==
                        "Extension_Institute"
                    ) {
                        history(`/view-data/${params.instituteCode}`)
                    } else {
                        if (extension !== null) {
                            let all_extensions = extension.split("-")
                            let ext2 = all_extensions[all_extensions.length - 1]
                            history(
                                `/view-data/${params.instituteCode}-${ext2}`
                            )
                        }
                    }
                }
            })
            .catch(() => {})
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleQuickFilter = (event) => {
        gridRef.current.api.setQuickFilter(event.target.value)
    }

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.setDomLayout("autoHeight")
    }, [])

    useEffect(() => {
        if (gridApi) getAllExtensionCode()
    }, [gridApi, extension])

    const onBtnBack = () => {
        if (extensionHistory.length == 1) {
            setExtension(null)
            setExtensionHistory([])
        } else {
            extensionHistory.pop()
            setExtension(extensionHistory[extensionHistory.length - 1])
        }
    }

    return (
        <Fragment>
            <section className="blackBG">
                <div className="container-fluid">
                    <Header
                        insti_code={instituteCode}
                        code={tickers.alias}
                        setToken={setToken}
                        iLogo={window.sessionStorage.getItem("logo")}
                    />
                    <Dash
                        tickers={tickers}
                        isLoading={isLoading}
                        contains={contains}></Dash>
                    <br />
                    <input
                        type="text"
                        id="filter-text-box"
                        placeholder="Search..."
                        onChange={handleQuickFilter}
                        className="form-control"></input>
                    <br />
                    {extensionHistory.length > 0 && (
                        <div className="float-left">
                            <button
                                onClick={onBtnBack}
                                className="btn btn-danger">
                                <i className="fa-solid fa-arrow-left"></i> Back
                            </button>
                        </div>
                    )}

                    <div className="float-right ">
                        <button
                            onClick={onBtnExport}
                            className="btn btn-secondary">
                            <i className="fa-solid fa-file-excel"></i> Export to
                            Excel
                        </button>
                    </div>
                    <br />
                    <br />
                    <div className="rr-card">
                        <div
                            className="ag-theme-alpine"
                            style={{height: "300px", width: "100%"}}>
                            <AgGridReact
                                rowData={code}
                                ref={gridRef}
                                pagination={true}
                                paginationPageSize={10}
                                onFirstDataRendered={onFirstDataRendered}
                                frameworkComponents={{
                                    LinkComponent,
                                    JudgeComponent,
                                }}
                                onGridReady={onGridReady}
                                enableCellTextSelection={true}>
                                {columns.map((column, index) => (
                                    <AgGridColumn {...column} key={index} />
                                ))}
                            </AgGridReact>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )

    function LinkComponent(props) {
        return (
            <>
                {/* <Link
                    to={`/view-data/${params.instituteCode}-${props.data.code}`}>
                    {props.value}
                </Link> */}
                <button
                    className="btn-blank"
                    onClick={(e) => handleClick(props.data.code)}>
                    {props.value}
                </button>
            </>
        )
    }

    function JudgeComponent(props) {
        return (
            <p
                className={
                    props.data.no_of_user !== props.value
                        ? "text-danger"
                        : "text-success"
                }>
                {props.value}
            </p>
        )
    }
}

export default ExtensionPage
