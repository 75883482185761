import React from "react"
import { ThreeDots } from "react-loader-spinner"

export const LoaderLarge = () => (
    <div className="loader">
        <ThreeDots type="ThreeDots" color="#1a1a1a" height={50} width={50} />
    </div>
)

export const LoaderSmall = () => (
    <div className="loader">
        <ThreeDots type="ThreeDots" color="#1a1a1a" height={28} width={28} />
    </div>
)
