import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react"
export default forwardRef((props, ref) => {
    const [year, setYear] = useState("all")

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {
            // doesFilterPass(params) {
            //     return true
            // },

            isFilterActive() {
                return year != "all"
            },

            // this example isn't using getModel() and setModel(),
            // so safe to just leave these empty. don't do this in your code!!!
            getModel() {
                if (year != "all") {
                    return {
                        filter: year,
                        type: "equals",
                    }
                } else return null
            },

            setModel() {},
        }
    })

    const onYearChange = (event) => {
        setYear(event.target.value)
    }

    useEffect(() => {
        props.filterChangedCallback()
    }, [year])

    return (
        <div
            style={{display: "inline-block", width: "400px"}}
            onChange={onYearChange}>
            <label
                style={{
                    margin: "10px",
                    display: "inline-block",
                }}>
                <input
                    type="radio"
                    name="year"
                    value="all"
                    checked={year === "all"}
                />{" "}
                All
            </label>
            <label
                style={{
                    margin: "10px",
                    display: "inline-block",
                }}>
                <input
                    type="radio"
                    name="year"
                    value="true"
                    checked={year === "true"}
                />{" "}
                Downloaded
            </label>
            <label
                style={{
                    margin: "10px",
                    display: "inline-block",
                }}>
                <input
                    type="radio"
                    name="year"
                    value="false"
                    checked={year === "false"}
                />{" "}
                Not Downloaded
            </label>
        </div>
    )
})
