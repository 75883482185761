import React, {useState, useEffect, useRef} from "react"
import axios from "axios"
import {baseUrl} from "../StaticValues"
import Header from "./components/header"
import Accordion from "react-bootstrap/Accordion"
import {useSearchParams, useParams} from "react-router-dom"
import {LoaderSmall} from "./components/loaders"

const UserDetailPage = ({setToken}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])
    const [onboardingtestdata, setOnboardingTestData] = useState([])
    const [finaltestdata, setFinalTestData] = useState([])
    const params = useParams()

    const downloadUserData = () => {
        axios({
            method: "get",
            url: baseUrl + "/getStudentInformation/?user_id=" + params.userId,
            headers: {
                Authorization:
                    "Bearer " + window.sessionStorage.getItem("access_token"),
            },
        })
            .then((res) => {
                // console.log(res.data)
                setIsLoading(false)
                setData(res.data.quiz)
                setOnboardingTestData(res.data.onboarding_test)
                setFinalTestData(res.data.final_test)
            })
            .catch((err) => {})
    }

    useEffect(() => {
        downloadUserData()
    }, [])

    return (
        <div className="container-fluid">
            <Header code={""} />
            <h3 className="mt-3">
                {searchParams.get("user_name")}{" "}
                {searchParams.get("download_status") === "true" ? (
                    <i className="fas fa-check-circle text-success"></i>
                ) : (
                    <i className="fa-solid fa-circle-xmark wrong"></i>
                )}
            </h3>
            <div className="row mt-3 mb-5">
                <div className="col-lg-3">
                    <div className="card">
                        <div className="card-body">
                            <h2>{isLoading ? <LoaderSmall /> : data.length}</h2>
                            <p className="card-text">Number of Articles Read</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="card">
                        <div className="card-body">
                            <h2>{isLoading ? <LoaderSmall /> : data.length}</h2>
                            <p className="card-text">
                                Number of Articles Unlocked
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="card">
                        <div className="card-body">
                            <h2>{searchParams.get("avg_time_spent")}</h2>
                            <p className="card-text">Average Time Spent</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="card">
                        <div className="card-body">
                            <h2>{searchParams.get("aggregate_quiz_score")}</h2>
                            <p className="card-text">Aggregate Quiz Score</p>
                        </div>
                    </div>
                </div>
            </div>
            <h4>Onboarding Test</h4>
            {isLoading && <LoaderSmall />}
            {!isLoading && onboardingtestdata.length === 0 && (
                <p>No Information found.</p>
            )}
            {!isLoading && !onboardingtestdata.length === 0 && (
                <p>No Information found.</p>
            )}
            <h4>Final Test</h4>
            {isLoading && <LoaderSmall />}
            {!isLoading && finaltestdata.length === 0 && (
                <p>No Information found.</p>
            )}
            <h4>Article wise details</h4>
            {isLoading && <LoaderSmall />}
            {!isLoading && data.length === 0 && <p>No Information found.</p>}
            {!isLoading && data.length != 0 && (
                <Accordion>
                    {data.map((item, index) => {
                        return (
                            <Accordion.Item
                                eventKey={index}
                                className="acc-btn">
                                <Accordion.Header>
                                    {item.art_heading}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="row mt-2 mb-2">
                                        <div className="col-lg-6">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h2>
                                                        <i className="fa-solid fa-circle-xmark wrong"></i>
                                                    </h2>
                                                    <p className="card-text">
                                                        Read Status
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h2>
                                                        {fancyTimeFormat(
                                                            item.time_in_seconds
                                                        )}
                                                    </h2>
                                                    <p className="card-text">
                                                        Time Spent in the
                                                        Article
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {item.quiz.length > 0 && (
                                        <div>
                                            {item.quiz.map((qu, key) => {
                                                return (
                                                    <div className="" key={key}>
                                                        <h5 className="quiz-question mt-2">
                                                            {qu.question}
                                                        </h5>
                                                        <div className="quiz-answer-list row mt-3 mb-3">
                                                            {qu.options
                                                                .split("##")
                                                                .map(
                                                                    (
                                                                        option,
                                                                        k
                                                                    ) => {
                                                                        if (
                                                                            k !=
                                                                            0
                                                                        )
                                                                            return (
                                                                                <div
                                                                                    className="col-lg-6"
                                                                                    key={
                                                                                        k
                                                                                    }>
                                                                                    <p
                                                                                        className={
                                                                                            k ==
                                                                                            qu.correct
                                                                                                ? "text-success"
                                                                                                : k ==
                                                                                                  qu.selected
                                                                                                ? "text-warning"
                                                                                                : ""
                                                                                        }>
                                                                                        {
                                                                                            option
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                            )
                                                                    }
                                                                )}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
            )}
        </div>
    )
    function fancyTimeFormat(duration) {
        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600)
        var mins = ~~((duration % 3600) / 60)
        var secs = ~~duration % 60

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = ""

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "")
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "")
        ret += "" + secs
        return ret
    }
}
export default UserDetailPage
