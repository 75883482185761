// import logo from './logo.svg';
import "./App.css"
import React, {useEffect, useState} from "react"
import LandingPage from "./pages/landing"
import LoginPage from "./pages/login"
import ChangePassword from "./pages/changePassword"
import {
    BrowserRouter,
    Route,
    Routes,
    Navigate,
    useNavigate,
} from "react-router-dom"

// COMPONENTS
import DetailPage from "./pages/detailPage"
import ExtensionPage from "./pages/extensionPage"
import NotValidUser from "./pages/components/notValidUser"
import Information from "./pages/information"
import Categories from "./pages/categories"
import UserDetailPage from "./pages/userDetailPage"

function App() {
    let tokenFromSession = window.sessionStorage.getItem("token")

    let user_name = window.sessionStorage.getItem("user_name")
    let access_type = window.sessionStorage.getItem("access_type")

    const [token, setToken] = useState(tokenFromSession)
    const [userType, setUserType] = useState(access_type)

    useEffect(() => {
        // console.log({ user_name })
        // console.log({ access_type })
        setToken(window.sessionStorage.getItem("token"))
    }, [])

    if (!token)
        return <LoginPage setToken={setToken} setUserType={setUserType} />
    // else {
    //     if (type) {
    //         if (type == "Master") return history.push("/")
    //         else if (type == "Institute")
    //             return history.push("/extension/" + code)
    //         else if (type == "Extension_Institute")
    //             return history.push("/view-data/" + code)
    //     }

    // }

    // MASTER _all
    // INSTI - extensiona and viewdata
    // Ext. inti -> view-data

    // Validation in login
    // Alert

    return (
        <>
            <Routes>
                <Route
                    path="/information"
                    element={
                        <Information
                            setToken={setToken}
                            insti_code={window.sessionStorage.getItem("code")}
                        />
                    }
                />
                <Route
                    path="/view-data/:instituteCode"
                    element={<DetailPage setToken={setToken} />}
                />
                <Route
                    path="/user-details/:userId"
                    element={<UserDetailPage setToken={setToken} />}
                />
                <Route
                    path="/extension/:instituteCode"
                    element={
                        userType == "Master" ||
                        userType == "Institute" ||
                        userType == "Extend_Extension_Institute" ||
                        userType == "Extension_Institute" ? (
                            <ExtensionPage setToken={setToken} />
                        ) : (
                            <NotValidUser
                                userType={userType}
                                setToken={setToken}
                            />
                        )
                    }
                />
                <Route
                    path="/"
                    element={
                        userType == "Master" ? (
                            <LandingPage setToken={setToken} />
                        ) : (
                            <NotValidUser
                                userType={userType}
                                setToken={setToken}
                            />
                        )
                    }
                />
                <Route
                    path="/categories"
                    element={
                        <Categories
                            setToken={setToken}
                            insti_code={window.sessionStorage.getItem("code")}
                        />
                    }
                />
                <Route path="/change-password" element={<ChangePassword />} />
            </Routes>
        </>
    )
}

export default App
