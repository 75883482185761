import React, { useState, useEffect } from "react"
import axios from "axios"
import { baseUrl } from "../StaticValues"
import { Navigate, useNavigate } from "react-router-dom"
import Header from "./components/header"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { LoaderSmall } from "./components/loaders"

export default function ChangePassword() {
    const [oldpassword, setOldPassword] = useState("")
    const [newpassword, setNewPassword] = useState("")
    const [confirmpassword, setConfirmPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        const form = e.target
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            e.target.className += " was-validated"
            return
        }
        e.preventDefault()
        if (newpassword != confirmpassword) {
            toast.error(`New password and confirm password is not same.`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } else {
            let url = baseUrl + "/changePassword/"
            setIsLoading(true)
            let passwordFormData = new FormData()
            passwordFormData.append(
                "user",
                window.sessionStorage.getItem("user_name")
            )
            passwordFormData.append("current_password", oldpassword)
            passwordFormData.append("new_password", newpassword)
            axios({
                method: "post",
                url: url,
                data: passwordFormData,
                headers: {
                    Authorization:
                        "Bearer " +
                        window.sessionStorage.getItem("access_token"),
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        setConfirmPassword("")
                        setNewPassword("")
                        setOldPassword("")
                        setIsLoading(false)
                        toast.success(`Password Changed Successfully!`, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                })
                .catch((err) => {
                    console.log(err.response)
                    if (err.response.status === 400) {
                        setConfirmPassword("")
                        setNewPassword("")
                        setOldPassword("")
                        setIsLoading(false)
                        toast.error(`${err.response.data.message}`, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                })
                .finally(() => {})
        }
    }

    return (
        <div className="container">
            <Header code={""} />
            <form
                onSubmit={handleSubmit}
                className="needs-validation"
                noValidate
            >
                <div className="form-group">
                    <label>Old Password</label>
                    <input
                        type="password"
                        placeholder="Enter old password"
                        className="form-control"
                        value={oldpassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        required
                    />
                    <div className="invalid-feedback">
                        Please fill out this field.
                    </div>
                </div>
                <div className="form-group">
                    <label>New Password</label>
                    <input
                        type="password"
                        placeholder="Enter your password"
                        className="form-control"
                        value={newpassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    <div className="invalid-feedback">
                        Please fill out this field.
                    </div>
                </div>
                <div className="form-group">
                    <label>Confirm Password</label>
                    <input
                        type="password"
                        placeholder="Enter your password"
                        className="form-control"
                        value={confirmpassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <div className="invalid-feedback">
                        Please fill out this field.
                    </div>
                </div>
                <div>
                    <button
                        type="submit"
                        className="btn btn-warning"
                        disabled={isLoading ? true : false}
                    >
                        {isLoading ? <LoaderSmall /> : "Submit"}
                    </button>
                    <div className="invalid-feedback">
                        Please fill out this field.
                    </div>
                </div>
            </form>
            <ToastContainer />
        </div>
    )
}
